import { PropTypes } from "prop-types";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

CompanyTypeBodyTemplate.propTypes = {
  typeFieldData: PropTypes.string,
};

function CompanyTypeBodyTemplate(typeFieldData){
  const getTypeInfo = (type) => {
    switch (type.toLowerCase()) {
      case "private_company":
        return {
          title: "Private Company",
          icon: "lock"
        };

      case "public_company":
        return {
          title: "Public Company",
          icon: "building-columns"
        };

      case "individual":
        return {
          title: "Individual",
          icon: "user-large"
        };
    }
  };

  const { title, icon } = getTypeInfo(typeFieldData);

  return (
    <Tag
      icon={(options) => (
        <FontAwesomeIcon
          className={"status-icon grey"}
          icon={["fas", icon]}
          {...options.iconProps}
        />
      )}
      value={title}
      severity="grey"
    ></Tag>
  );
};

export default CompanyTypeBodyTemplate;

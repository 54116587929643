import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import apiAxios from "../../../axios.js";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Toolbar } from "primereact/toolbar";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Header from "../../includes/Header.jsx";
import { apiWrapper } from "../../common/apiWrapper.js";
import InvoiceFilterActions from "../../actions/InvoiceFilterActions.jsx";
import sortHelper from "../../helpers/sortHelper.jsx";

import formatDate from "../../helpers/formatDate.jsx";
import formatCurrency from "../../helpers/formatCurrency.jsx";
import formatDecimal from "../../helpers/formatDecimal.jsx";
import SaveAsExcelHelper from "../../helpers/SaveAsExcelHelper.jsx";

library.add(fas);

function BuyerSupplierInvoices() {
  const { pk } = useParams();

  function InvoiceContent() {
    const navigate = useNavigate();
    const invoicesDataTableReference = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [supplier, setSupplier] = useState({});
    const [isClearFilterVisible, setIsClearFilterVisible] = useState(false);
    const [tableLazyState, setTableLazyState] = useState({
      first: 0,
      rows: 25,
      page: 1,
      sortField: null,
      sortOrder: null,
      filters: {}
    });

    const updateTableLazyState = (newState) => {
      setTableLazyState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    };

    const onPage = (event) => {
      updateTableLazyState({
        first: event.first,
        rows: event.rows,
        page: event.page + 1,
      });
    };

    const onSort = (event) => {
      const { sortField, sortOrder } = event;

      updateTableLazyState({
        sortField,
        sortOrder,
      });
    };

    const onSearch = async (searchTerm) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          search_term: searchTerm,
          /* eslint-enable camelcase */
        },
      });
    };

    const onDateChange = (startDate, endDate) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          start_date: startDate,
          end_date: endDate,
          /* eslint-enable camelcase */
        },
      });
    };

    const onAmountChange = (minAmount, maxAmount) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          min_amount: minAmount,
          max_amount: maxAmount,
          /* eslint-enable camelcase */
        },
      });
    };

    const loadInvoiceTable = useCallback(async () => {
      setLoading(true);
      const requestParams = {
        /* eslint-disable camelcase */
        program_pk: pk,
        /* eslint-enable camelcase */
        page: tableLazyState.page,
        pageSize: tableLazyState.rows,
        sortField: tableLazyState.sortField,
        sortOrder: tableLazyState.sortOrder,
        ...tableLazyState.filters
      };

      const invoiceDataWrapper = apiWrapper(() =>
        apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/buyer/supplier/show_invoices?supplier_pk=${pk}`, {
          params: requestParams
        })
      );

      try {
        const response = await invoiceDataWrapper.read();
        if (response?.data) {
          setInvoices(response.data.invoices || []);
          setTotalRecordCount(response.data.invoices.length || 0);
          setSupplier(response.data.supplier || {});
        }
        setLoading(false);
      } catch (promiseOrError) {
        if (promiseOrError instanceof Promise) {
          promiseOrError.then(async () => {
            try {
              const response = await invoiceDataWrapper.read();
              if (response?.data) {
                setInvoices(response.data.invoices || []);
                setTotalRecordCount(response.data.invoices.length || 0);
                setSupplier(response.data.supplier || {});
              }
            } catch (error) {
              setInvoices([]);
              setTotalRecordCount(0);
              setSupplier({});
            } finally {
              setLoading(false);
            }
          });
        } else {
          setInvoices([]);
          setTotalRecordCount(0);
          setSupplier({});
          setLoading(false);
        }
      }
    }, [tableLazyState]);

    useEffect(() => {
      loadInvoiceTable();
    }, [loadInvoiceTable]);

    const sortedInvoices = sortHelper(invoices, tableLazyState.sortField, tableLazyState.sortOrder);

    const leftToolbarHeaderTemplate = () => {
      return (
        <>
          <div className="flex flex-wrap gap-2">
            <h1>{supplier.legal_name}</h1>
          </div>
        </>
      );
    };

    const rightToolbarOptionsTemplate = () => {
      return (
        <Button
          className="has-icon"
          label="Export table"
          icon={(options) => <FontAwesomeIcon icon={["fas", "file-export"]}  {...options.iconProps} /> } 
          rounded
          severity="dark"
          onClick={exportExcel}
        />
      );
    };

    const viewInvoiceTemplate = (rowData) => {
      return (
        <Button
          className="has-icon"
          label="View Invoice"
          icon={(options) => <FontAwesomeIcon icon={["fas", "play"]}  {...options.iconProps} />}
          rounded
          outlined
          severity="grey"
          onClick={() => navigate(`/buyer/supplier/invoices/show_invoice_detail/${rowData.pk}`)}
        />
      );
    };

    const exportExcel = () => {
      const formattedInvoice = sortedInvoices.map((invoice) => {
        return {
          "Invoice": invoice.invoice_number,
          "Due date": formatDate(invoice.due_at),
          "Currency": invoice.currency,
          "Invoice value": formatDecimal(invoice.amount),
          "Owner": invoice.owner
        };
      });
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(formattedInvoice);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });

        SaveAsExcelHelper(excelBuffer, "invoices");
      });
    };

    return (
      <div className="card page-content">
        <div className="card-body">
          <Toolbar className="p-0 pt-2" start={leftToolbarHeaderTemplate}></Toolbar>
          <Toolbar
            className="mb-4"
            start={
              <InvoiceFilterActions
                onSearch={onSearch}
                onDateChange={onDateChange}
                onAmountChange={onAmountChange}
                updateTableLazyState={updateTableLazyState}
                setIsClearFilterVisible={setIsClearFilterVisible}
                isClearFilterVisible={isClearFilterVisible}
              />}
            end={rightToolbarOptionsTemplate}
          />
          <div className="position-relative">
            <DataTable
              ref={invoicesDataTableReference}
              value={sortedInvoices}
              dataKey="invoice_number"
              lazy
              removableSort
              paginator
              rows={tableLazyState.rows}
              rowsPerPageOptions={[25, 50, 100]}
              paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              first={tableLazyState.first}
              totalRecords={totalRecordCount}
              onPage={onPage}
              onSort={onSort}
              sortField={tableLazyState.sortField}
              sortOrder={tableLazyState.sortOrder}
              loading={loading}
            >
              <Column field="invoice_number" header="Invoice" sortable style={{ minWidth: "10rem" }}></Column>
              <Column field="due_at" body={(rowData) => formatDate(rowData.due_at)} header="Due date" sortable style={{ maxWidth: "10rem" }}></Column>
              <Column field="amount" body={(rowData) => formatCurrency(rowData.amount, rowData.currency)} header="Invoice value" sortable style={{ maxWidth: "10rem" }}></Column>
              <Column field="owner" body={(rowData) => rowData.owner} header="Owner" sortable style={{ maxWidth: "10rem" }}></Column>
              <Column field="" header="" body={(rowData) => viewInvoiceTemplate(rowData)} style={{ maxWidth: "10rem" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <main>
        <div className="container my-4">
          <InvoiceContent />
        </div>
      </main>
    </>
  );
}

export default BuyerSupplierInvoices;

import { isEmpty } from "./isEmptyHelpers.jsx";

function formatDecimal(numberFieldData) {
  if (isEmpty(numberFieldData)) {
    return "";
  };

  return parseFloat(numberFieldData / 100).toFixed(2);
}

export default formatDecimal;
